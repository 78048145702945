/*===> Begin history-list <===*/
.history-list {
	position: relative;
	display: flex;
	overflow: hidden;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		width: 1px;
		height: calc(100% - 60px);
		border: 1px solid var(--text_gray);
	}

	.load-history {
		position: absolute;
		bottom: 0;
		left: 50%;
		z-index: 2;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background: #E8E8E8;
		transform: translate(-50%, -50%);
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			@include linear-gradient(-45deg, var(--main_color), var(--main_color_2));
		}

		&::after {
			content: '+';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #fff;
			font-family: var(--main_font);
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			line-height: 1em;
			text-align: center;
			color: #D3D3D5;
			transform: translate(-50%, -50%);
		}
	}

	.left-column {
		width: 50%;
		.history-block {
			text-align: right;
			padding-left: 0;
			padding-right: 115px;
		}
	}

	.right-column {
		width: 50%;
		padding-top: 60px;

		.history-block .dot {
			right: auto;
			left: 0;
			flex-direction: row-reverse;
			transform: translateX(-13px);
		}
	}

	.history-block {
		position: relative;
		max-width: 585px;
		padding-left: 115px;
		margin-bottom: 60px;

		.dot {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
			width: 110px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			transform: translateX(13px);

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: var(--text_gray);
			}

			span {
				&:first-child {
					position: relative;
					z-index: 2;
					display: block;
					width: 9px;
					height: 9px;
					border-radius: 50%;
					background: var(--slick_dots_bg);
				}

				&:last-child {
					position: relative;
					display: block;
					width: 26px;
					height: 26px;
					border-radius: 50%;
					background: #E8E8E8;

					&::before {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						width: 16px;
						height: 16px;
						border-radius: 50%;
						transform: translate(-50%, -50%);
						@include linear-gradient(-45deg, var(--main_color), var(--main_color_2));
					}

					&::after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						width: 12px;
						height: 12px;
						border-radius: 50%;
						background: #fff;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.data {
			margin-bottom: 15px;
			font-size: 15px;
			color: var(--main_color);
		}

		img {
			margin-bottom: 15px;
		}

		p {
			margin-bottom: 0;
		}
	}

	@include breakpoint(medium) {
		.history-block {
			padding-left: 50px;
			margin-bottom: 40px;

			.dot {
				width: 50px;
			}
		}

		.left-column {
			.history-block {
				padding-left: 0;
				padding-right: 50px;
			}
		}
	}

	@include breakpoint(extra-small) {
		flex-direction: column;
		padding-bottom: 20px;
		padding-left: 25px;

		&::after {
			// display: none;
			left: 0;
			height: 100%;
		}

		.load-history {
			left: 0;
			bottom: 0;
			width: 30px;
			height: 30px;
			transform: translate(-50%, 0);

			&::before {
				width: 28px;
				height: 28px;
			}

			&::after {
				width: 26px;
				height: 26px;
			}
		}

		.left-column,
		.right-column {
			width: 100%;
		}

		.left-column {
			.history-block {
				text-align: left;
			}
		}

		.right-column {
			padding-top: 0;

			.history-block {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.history-block {
			margin-bottom: 30px;
			padding-left: 0 !important;
			padding-right: 0 !important;

			.dot {
				left: 0 !important;
				right: auto !important;
				flex-direction: row-reverse;
				width: 38px;
				transform: translateX(-36px) !important;
				// display: none;

				span {
					&::before {
					}
					&:first-child {
						transform: scale(0);
					}

				}
			}
		}
	}
}
.history-list-add{
	display: none;
}
/*===> End history-list <===*/