.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: var(--main_dark);
  transition: all .5s;

  &.load {
    opacity: 0;
    visibility: hidden;
  }
}
.loader-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #181818;
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.loader-wrapper.disable {
  opacity: 0;
  visibility: hidden;
}
.loader-container {
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  pointer-events: none;
}
.loader-container > div {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 2px;
  margin: 0 1px;
  -webkit-animation: load 3s ease-in-out infinite;
  animation: load 3s ease-in-out infinite;
}
.loader-container .rectangle-2 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.loader-container .rectangle-3 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loader-container .rectangle-4 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loader-container .rectangle-5 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loader-container .rectangle-6 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes load {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    background: #7B16D9;
  }
  16.67% {
    -webkit-transform: scaleY(3);
    background: #d916c1;
  }
  33.33% {
    -webkit-transform: scaleY(1);
    background: #378ed6;
  }
  50% {
    -webkit-transform: scaleY(3);
    background: #d54c45;
  }
  66.67% {
    -webkit-transform: scaleY(1);
    background: #d54d44;
  }
  83.34% {
    -webkit-transform: scaleY(3);
    background: #FF6600;
  }
}
@keyframes load {
  0%,
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    background: #7B16D9;
  }
  16.67% {
    -webkit-transform: scaleY(3);
            transform: scaleY(3);
    background: #d916c1;
  }
  33.33% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    background: #378ed6;
  }
  50% {
    -webkit-transform: scaleY(3);
            transform: scaleY(3);
    background: #d54c45;
  }
  66.67% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    background: #d54d44;
  }
  83.34% {
    -webkit-transform: scaleY(3);
            transform: scaleY(3);
    background: #FF6600;
  }
}