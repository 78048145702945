/*===> Begin engineer-card <===*/
.engineer-card {
	position: relative;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	padding: 55px 30px;
	background: #fff;
	box-shadow: 0px 5px 20px rgba(52, 51, 55, 0.1);

	.engineer-card_info {
		position: relative;
		z-index: 2;

		h4 {}
		p {}
		a {}
	}

	.engineer-avatar {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 0;
		max-height: calc(100% - 7px);
		mix-blend-mode: luminosity;
	}

	@include breakpoint(small) {
		padding: 45px 25px;
	}

	@include breakpoint(extra-small) {
		padding: 40px 20px;

		.engineer-card_info {
			p {
				margin-bottom: 25px;
			}
		}
	}

	@include breakpoint(mobile) {
		padding: 30px 20px;
	}
}

.engineer-slider {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -30px;

	&.slick-initialized {
		margin-bottom: 0;

		.tt-slide {
			margin-bottom: -30px;
		}
	}

	.tt-slide {
		width: 33.33%;
		padding-left: 15px;
		padding-right: 15px;
	}
}
/*===> End engineer-card <===*/