/*===> Begin news-slider <===*/
.news-slider {
	margin-left: -15px;
	margin-right: -15px;

	.tt-slide {
		padding-left: 15px;
		padding-right: 15px;
	}
}
/*===> End news-slider <===*/