/*===> Begin comment-list <===*/
.comment-list {
	padding-top: 7px;
	margin-bottom: 50px;
	.comment-list__element {
		padding-left: 0;
		&::after {
			display: none;
		}
	}

	>.comment-list__element:first-child {
		>.comment {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}
	}

	.comment-list {
		padding-left: 80px;

		>.comment-list__element {
			>.comment:first-child {
				border-top: 1px  solid  rgba(var(--header_color_rgb), .2);
				margin-top: 30px;
				padding-top: 30px;
			}
		}
	}

	@include breakpoint(small) {
		margin-bottom: 30px;
		.comment-list {
			padding-left: 20px;
		}
	}
}

.comment {
	display: flex;
	margin-top: 30px;
	padding-top: 30px;
	border-top: 1px solid  rgba(var(--header_color_rgb), .2);

	.comment-avatar {
		position: relative;
		width: 80px;
		min-width: 80px;
		height: 80px;
		margin-right: 15px;
		border-radius: 50%;
		overflow: hidden;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}

	.comment-content {
		position: relative;
		p {
			margin-bottom: 0;
		}
	}

	.comment-info {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		flex-direction: column;

		p, time {
			display: block;
			margin-right: 15px;
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			color: var(--text_color);

			svg {
				margin-right: 5px;
				position: relative;
				top: -2px;
			}
		}
		.post-meta-author{
			a{
				font-family:'Poppins';
				font-weight: bold;
				color: #3d3b42;
				font-size: 18px;
			}
		}
		time{
			margin-top: 2px;
			padding-bottom: 16px;
		}
	}

	@include breakpoint(extra-small) {
		.comment-avatar {
			width: 46px;
			min-width: 46px;
			height: 46px;
		}
	}
	.btn.white.btn-border,
	.button.white.btn-border{
		padding: 8px 15px;
		display: inline-block;
		min-width: inherit;
		position: absolute;
		top: 0px;
		right: 0;
	}
}
/*===> End comment-list <===*/