/*===> Begin post-author <===*/
.post-author {
	position: relative;
	display: flex;
	align-items: flex-start;
	margin-top: 50px;
	padding: 40px;
	background-size: cover;
	background-position: center;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4px;
		@include linear-gradient(45deg, var(--main_color), var(--main_color_2));
	}

	.avatar {
		position: relative;
		width: 130px;
		min-width: 130px;
		height: 130px;
		margin-right: 30px;
		border-radius: 50%;
		overflow: hidden;
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}

	h5, .h5 {
		color: #fff;
	}

	p {
		color: var(--text_gray);
	}

	@include breakpoint(small) {
		margin-top: 40px;
		padding: 30px;
	}

	@include breakpoint(extra-small) {
		padding: 30px 20px;

		p {
			margin-bottom: 10px;
		}
	}

	@include breakpoint(mobile) {
		flex-direction: column;
		margin-top: 30px;
		text-align: center;

		.avatar {
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 20px;
		}

		.social-list {
			justify-content: center;
		}
	}
}
/*===> End post-author <===*/