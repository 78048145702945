/*===> Begin contact-form <===*/
.contact-form {
	input {
		margin-bottom: 15px;
	}

	button {
		margin-top: 30px;
	}
}
/*===> End contact-form <===*/