/*===> Begin tab-slider <===*/
.tab-slider {
	div {
		height: 100% !important;
	}
	.tt-slider {
		.studio-slide {
			background-size: cover !important;
			background-position: center center !important;
		}
	}
}
/*===> End tab-slider <===*/