/*===> Begin player <===*/
.player {
	padding: 40px 30px;
	background-size: cover;
	background-position: center;

	.awp-project-player {
		margin-bottom: 0;
	}

	@include breakpoint(extra-small) {
		padding: 30px 15px;
	}
}
/*===> End player <===*/