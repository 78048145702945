/*===> Begin product-card <===*/
.product-card {
	width: 100%;
	max-width: 290px;
	margin-left: auto;
	margin-right: auto;
	background: #fff;
	box-shadow: 0px 0px 10px rgba(var(--main_gray_rgb), 0.1);
	transition: box-shadow .3s;

	&:hover {
		box-shadow: 0px 0px 20px rgba(var(--main_gray_rgb), 0.2);
	}

	.product-card__image {
		position: relative;

		&:hover {
			.button {
				-webkit-transform: translate(-50%, -50%) scale(1);
				-moz-transform: translate(-50%, -50%) scale(1);
				-ms-transform: translate(-50%, -50%) scale(1);
				-o-transform: translate(-50%, -50%) scale(1);
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
			}
		}

		img {
			width: 100%;
		}
		
		.button {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%) scale(.9);
			-moz-transform: translate(-50%, -50%) scale(.9);
			-ms-transform: translate(-50%, -50%) scale(.9);
			-o-transform: translate(-50%, -50%) scale(.9);
			transform: translate(-50%, -50%) scale(.9);
			opacity: 0;
			transition: all .3s;
		}
	}

	.product-card__description {
		padding: 25px 20px;

		a {
			min-height: 2.4em;
			margin-bottom: 5px;
			font-family: var(--main_font);
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 1.2em;
			color: var(--text_color);

			&:hover {
				color: var(--main_color);
			}
		}

		.product-card-bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
.product-card__price {
	span {
		font-family: var(--header_font);
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 1.2em;
		letter-spacing: -0.03em;
		color: var(--header_color);

		&:nth-child(2) {
			margin-left: 5px;
		}

		&.old {
			position: relative;
			
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: var(--header_color);
			}
		}

		&.new {
			color: var(--main_color);
		}
	}
}
/*===> End product-card <===*/