/*===> Begin product-description <===*/
.product-description {
	h3, .h3 {
		margin-bottom: 35px;
	}

	.product-card__rating {
		display: flex;
		align-items: center;
		margin-bottom: 25px;

		svg {
			margin-right: 10px;
		}
	}

	.product-card__price {
		span {
			margin-bottom: 10px;
			font-size: 36px;
		}
	}

	.product-info {
		margin: 35px 0;
		padding: 30px 0;
		border-top: 1px solid rgba(var(--header_color_rgb), .2);
		border-bottom: 1px solid rgba(var(--header_color_rgb), .2);
	}

	.product-form {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 35px;

		input {
			width: 57px;
			text-align: center;
			margin-right: 20px;
			margin-bottom: 0;
		}
	}
}

.product-tabs {
	padding-top: 15px;
	.tab-links {
		justify-content: flex-start;
		margin-bottom: 0;
		padding: 0;
	}

	.tab-blocks {
		.tab-blocks__item {
			.tab-text {
				width: 100%;
				max-width: none;
				padding: 40px;
			}
		}
	}

	.comment .comment-info p, .comment .comment-info time, .comment .comment-info a {
		color: var(--text_gray);
	}

	.comment-list .comment-list>.comment-list__element>.comment:first-child {
		border-top: 1px solid rgba(var(--text_gray_rgb), 0.2);
	}
}

.similar-slider {
	.product-list {
		margin-bottom: 0;
	}

	.slick-dots {
		margin-top: 10px;
	}
}
/*===> End product-description <===*/