/*===> Begin icon <===*/
.custom-icon {
	position: relative;
	max-width: 150px;
	width: 100%;
	height: 138px;

	>svg {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
	}

	.icon_bg {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		transform: translate(-50%, -50%);
	}
}

@keyframes icon-bg {
	0% {
		d: path("M5.08989 84.081C10.08 96.1197 18.4633 106.583 28.5433 113.446C48.304 126.835 73.2545 127.623 95.5102 120.647C114.872 114.572 133.335 102.195 143.714 82.9559C161.28 50.2151 146.309 4.53553 110.481 0.260106C95.3106 -1.54007 81.7376 6.33571 68.6636 13.6489C54.4917 21.5247 43.2141 15.3366 28.3437 17.1368C8.48315 19.387 0.0998017 38.6264 0 59.1035C0 67.7669 1.79643 76.3177 5.08989 84.081Z") !important;
	}

	33% {
		d: path("M56.2174 134.842C71.0174 131.042 83.0174 119.142 96.3174 112.042C108.917 105.242 124.617 106.842 136.817 99.0423C148.417 91.5423 153.117 78.2423 150.117 64.9423C147.717 54.6423 141.517 45.5423 134.717 37.5423C119.217 19.4423 98.6174 4.54232 75.0174 1.24232C44.3174 -3.05769 16.3174 16.4423 5.21737 44.5423C-2.48263 64.1423 -1.38263 86.7423 7.31737 105.542C14.9174 122.242 30.6174 137.042 49.0174 135.942C51.5174 135.842 53.9174 135.442 56.2174 134.842Z") !important;
	}

	66% {
		d: path("M68.7 137.6C30.7 137.5 0 106.7 0 68.7002C0.1 30.7002 30.9 -0.0997572 68.9 0.000242819C106.9 0.100243 149.5 39.4002 149.4 68.9002C149.4 98.4002 106.7 137.6 68.7 137.6Z") !important;
	}

	99% {
		d: path("M5.08989 84.0812C10.08 96.1199 18.4633 106.583 28.5433 113.447C48.304 126.836 73.2545 127.623 95.5102 120.647C114.872 114.572 133.335 102.196 143.714 82.9561C161.28 50.2153 146.309 4.53578 110.481 0.26035C95.3106 -1.53983 81.7376 6.33596 68.6636 13.6492C54.4917 21.525 43.2141 15.3369 28.3437 17.137C8.48315 19.3873 0.0998017 38.6267 0 59.1037C0 67.7671 1.79643 76.318 5.08989 84.0812Z") !important;
	}

	100% {
		d: path("M5.08989 84.081C10.08 96.1197 18.4633 106.583 28.5433 113.446C48.304 126.835 73.2545 127.623 95.5102 120.647C114.872 114.572 133.335 102.195 143.714 82.9559C161.28 50.2151 146.309 4.53553 110.481 0.260106C95.3106 -1.54007 81.7376 6.33571 68.6636 13.6489C54.4917 21.5247 43.2141 15.3366 28.3437 17.1368C8.48315 19.387 0.0998017 38.6264 0 59.1035C0 67.7669 1.79643 76.3177 5.08989 84.081Z") !important;
	}
}
/*===> End icon <===*/

