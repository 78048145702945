/*===> Begin button <===*/
.btn,
.button {
	position: relative;
	min-width: 186px;
	padding: 20px 15px;
	border: none;
	border-radius: 30px;
	font-size: 16px;
	font-family: var(--header_font);
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	color: #fff;
	background-size: 100% 100%;
	-webkit-transition: background .3s ease-out, color .3s;
    -moz-transition: background .3s ease-out, color .3s;
    -o-transition: background .3s ease-out, color .3s;
    transition: background .3s ease-out, color .3s;
	@include linear-gradient(-45deg, var(--main_color), var(--main_color_2));

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		border-radius: 30px;
		@include linear-gradient(-45deg, var(--main_color), var(--main_color_2));
	}

	&:hover {
		background-size: 150% 100%;
		// color: var(--main_color);
		color: var(--header_color);
		cursor: pointer !important;

		svg path {
			fill: var(--main_color);
		}
	}

	&.btn-shadow {
		box-shadow: 0px 9px 20px #626065;
	}

	&.sm-text {
		font-size: 13px;
	}

	&.center-btn {
		display: block;
		width: 186px;
		margin-left: auto;
		margin-right: auto;
	}

	&.white {
		padding: 22px 17px;
		background: #fff;
		overflow: hidden;
		color: var(--header_color);

		&.btn-border {
			overflow: visible;
			padding: 20px 15px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: #fff;
				border-radius: 30px;
				transition: all .3s;
			}

			&::after {
				top: -2px;
				left: -2px;
				right: -2px;
				bottom: -2px;
				opacity: 1;
				z-index: -2;
				@include linear-gradient(-45deg, var(--main_color), var(--main_color_2));
			}

			&:hover {
				&::before {
					opacity: 0;
				}
			}
		}

		&:hover {
			background-color: transparent;
			// background: var(--main_dark);
			color: #fff;

			&::after {
				opacity: 1;
			}

			svg path {
				fill: #fff;
			}
		}

		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: all .3s;
		}

		svg {
			margin-right: 5px;
			path {
				fill: var(--main_color);
				transition: all .3s;
			}
		}
	}

	&.dark {
		background: var(--main_dark);
		color: #fff;

		&:hover {
			background: #fff;
			color: var(--header_color);
		}

		svg {
			path {
				fill: var(--main_color);
			}
		}
	}

	svg {
		margin-right: 5px;
		path {
			fill: #fff;
			// fill: var(--main_color);
			transition: all .3s;
		}
	}

	@include breakpoint(small) {
		font-size: 13px;
	}
}
/*===> End button <===*/