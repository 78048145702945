/*===> Begin review-block <===*/
.review-block {
	margin-bottom: 40px;

	&.review--dark {
		.review-content {
			background: var(--main_gray);

			&::after {
				background: var(--main_gray);
			}

			p {
				color: var(--text_gray);
			}
		}
	}

	.review-content {
		position: relative;
		margin-bottom: 30px;
		padding: 35px 40px;
		background: #fff;
		box-shadow: 0px 5px 20px rgba(52, 51, 55, 0.1);

		&::after {
			content: '';
			position: absolute;
			bottom: -8px;
			left: 26px;
			width: 40px;
			height: 40px;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			background: #fff;
			
		}

		p {
			margin-bottom: 20px;
		}
	}

	.review-author {
		display: flex;
		align-items: center;
	}

	.review-author__info {
		h4 {
			margin-bottom: 10px;
		}

		p {
			margin-bottom: 0;
		}
	}

	.avatar {
		position: relative;
		width: 87px;
		height: 87px;
		margin-right: 20px;
		border-radius: 50%;
		overflow: hidden;
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}
/*===> End review-block <===*/