/*===> Begin popular-product-list <===*/
.popular-product-list {
	.popular-product {
		margin-bottom: 15px;

		&:last-child{
			margin-bottom: 0;
		}
	}
}

.popular-product {
	display: flex;
	align-items: flex-start;

	>a {
		min-width: 80px;
		margin-right: 20px;
	}

	a {
		display: block;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
	}

	span {
		font-family: var(--header_font);
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: -0.03em;
		color: #fff;
	}
}
/*===> End popular-product-list <===*/