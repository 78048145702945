/*===> Begin prices-card <===*/
.prices-card {
	position: relative;
	width: 100%;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 55px;
	margin-bottom: 10px;
	box-shadow: 0px 7px 20px rgba(52, 51, 55, 0.2);

	&.popular-plan {

		.pricing-card__top {
			padding: 80px 15px 20px 15px;
		}
	}

	&:hover,
	&.popular-plan {
		.pricing-card__icon {
			background: var(--main_color);
			border-color: var(--main_color);

			svg path {
				fill: #fff;
			}
		}

		.pricing-card__content {
			background: var(--main_gray);

			h4 {
				color: #fff;
			}

			.prisec-card__include {
				p {
					color: var(--text_gray);
					border-bottom-color: rgba(255,255,255, .12);
				}
			}

			.button, .button.white, .btn.white {
				color: #fff;
			}

			.button:before, .button.white:before, .btn.white:before {
				background: var(--main_gray) !important;
			}
		}
	}

	.pricing-card__top {
		background-size: cover;
		background-position: center, center;
	}

	.pricing-card__icon {
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 1;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border-radius: 50%;
		border: 2px solid #F2F2F2;
		box-shadow: 0px 0px 20px rgba(52, 51, 55, 0.1);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		transition: all .3s;

		svg path {
			transition: all .3s;
		}
	}

	.pricing-card__top {
		text-align: center;
		padding: 65px 15px 20px 15px;

		p {
			margin-bottom: 0;
			font-family: var(--header_font);
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 1em;
			letter-spacing: -0.03em;
			color: #FFFFFF;

			span {
				font-family: var(--header_font);
				font-style: normal;
				font-weight: bold;
				font-size: 68px;
				line-height: 1em;
				letter-spacing: -0.03em;
				color: var(--main_color);
			}
		}
	}

	.pricing-card__content {
		padding: 45px 15px;
		background: #fff;
		text-align: center;
		transition: all .3s;

		h4 {
			margin-bottom: 30px;
		}

		h4, p {
			transition: all .3s;
		}

		.prisec-card__include {
			max-width: 270px;
			margin-left: auto;
			margin-right: auto;

			p {
				margin-bottom: 0;
				padding: 10px 0;
				border-bottom: 1px solid rgba(var(--header_color_rgb), .3);

				&:last-child {
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		.button {
			position: relative;
			z-index: 10;
		}
	}

	@include breakpoint(small) {
		.pricing-card__top {
			p {
				font-size: 16px;

				span {
					font-size: 50px;
				}
			}
		}

		.pricing-card__content {
			h4 {
				font-size: 18px;
			}

			h4, p {
				transition: all .3s;
			}

			.prisec-card__include {
				max-width: 270px;
				margin-left: auto;
				margin-right: auto;

				p {
					margin-bottom: 0;
					padding: 10px 0;
					border-bottom: 1px solid rgba(var(--header_color_rgb), .3);

					&:last-child {
						padding-bottom: 0;
						border-bottom: 0;
					}
				}
			}

			.button {
				position: relative;
				z-index: 10;
			}
		}
	}
}

.prices-slider {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: -15px;
	margin-right: -15px;

	.tt-slide {
		width: 33.33%;
		padding-left: 15px;
		padding-right: 15px;
	}

	@include breakpoint(extra-small) {
		.tt-slide {
			width: 100%;
		}
	}
}
/*===> End prices-card <===*/