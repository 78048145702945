/*===> Begin main-slider <===*/
.main-slider {
	background: var(--main_dark);
	.tt-slide {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 100vh;
		&.animate {
			.tt-slide_description{
				opacity: 1;
				transform: translateY(0);
			}

			.btn, .button {
				opacity: 1;
			}

			.tt-slide_header {
				opacity: 1;
				transform: translateX(0);

				span {
					transform: translateX(0);
				}
			}
		}
		.slide-bg {
			position: absolute;
			overflow: hidden;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			opacity: .3;
			background-size: cover !important;
			background-position: center center !important;
			background-attachment: fixed !important;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				min-height: 100%;
				max-width: none;
				transform: translate(-50%, -50%);
			}
		}
		.container {
			position: relative;
			z-index: 10;
		}
		.tt-slide_description {
			margin-bottom: 30px;
			font-size: 20px;
			font-weight: bold;
			line-height: 20px;
			color: #fff;
			opacity: 0;
			transform: translateY(-40px);
			transition: all .3s;
		}
		.tt-slide_header {
			margin-bottom: 50px;
			font-size: 64px;
			font-weight: bold;
			font-family: var(--header_font);
			line-height: 67px;
			letter-spacing: -0.03em;
			color: #fff;
			transition: all .3s;
			opacity: 0;
			transform: translateX(80px);

			span {
				display: inline-block;
				text-transform: uppercase;
				transform: translateX(-160px);
				transition: all .3s;
			}
		}
		.btn, .button {
			opacity: 0;
			transition: all .3s;
		}
	}

	@include breakpoint(small) {
		.tt-slide {
			min-height: 400px;

			.slide-bg {
				img {
					height: auto;
				}
			}

			.tt-slide_description {
				margin-bottom: 30px;
				font-size: 16px;
				line-height: 16px;
			}

			.tt-slide_header {
				margin-bottom: 50px;
				font-size: 51px;
				line-height: 56px;
			}
		}
	}

	@include breakpoint(extra-small) {
		.tt-slide {
			.tt-slide_description {
				margin-bottom: 20px;
				font-size: 12px;
				line-height: 12px;
			}

			.tt-slide_header {
				margin-bottom: 30px;
				font-size: 35px;
				line-height: 39px;
			}
		}
	}
}

.main-slider-wrap {
	position: relative;
}
/*===> End main-slider <===*/