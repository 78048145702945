/*===> Begin hamburger <===*/
.hamburger {
	position: relative;
	display: none;
	width: 18px;
	min-width: 18px;
	height: 16px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;

	&.open {
		span {
			&:nth-child(1) {
				top: 7px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}

			&:nth-child(2) {
				opacity: 0;
				right: 60px;
			}

			&:nth-child(3) {
				top: 7px;
				width: 100%;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-o-transform: rotate(-135deg);
				transform: rotate(-135deg);
			}
		}
	}

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #fff;
		border-radius: 2px;
		opacity: 1;
		right: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2) {
			top: 7px;
		}

		&:nth-child(3) {
			top: 14px;
		}
	}

	@include breakpoint(medium) {
		display: block;
	}
}
/*===> End hamburger <===*/