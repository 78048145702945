/*===> Begin banner-booking <===*/
.banner-booking {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 47px 95px 42px;
	background: var(--main_gray);

	.banner-text {
		max-width: 580px;
		padding: 0 15px;
		text-align: right;

		h2 {
			color: #fff;
		}

		p {
			margin-bottom: 0;
			font-size: 19px;
			font-weight: bold;
			color: var(--text_gray);
		}
	}

	.banner-button {
		padding: 0 15px;
		text-align: center;

		.button {
			position: relative;
			z-index: 1;
			margin-bottom: 13px;
			min-width: 284px;
		}

		p {
			margin-bottom: 0;
			color: var(--text_gray);
		}
	}

	@include breakpoint(medium) {
		padding: 40px 60px;
	}

	@include breakpoint(small) {
		flex-direction: column;
		padding: 30px 30px;

		.banner-text {
			max-width: none;
			margin-bottom: 20px;
			padding: 0;
			text-align: center;

			p {
				font-size: 16px;
			}
		}

		.banner-button {
			.button {
				min-width: 200px;
				width: 200px;
			}
		}
	}

	@include breakpoint(extra-small) {
		padding: 20px 15px;

		.banner-text {
			p {
				font-size: 15px;
			}
		}
	}
}
/*===> End banner-booking <===*/