/*===> Begin parralax-section <===*/
.parralax-section {
	position: relative;
	>* {
		position: relative;
		z-index: 10;
	}

	.parallax-img {
		position: absolute;
		top: 0;
		left: -150px;
		z-index: 0;
		&.animate {
			animation: parallax-img 3.3s infinite ease-in-out;
		}

		&.parallax-right {
			left: auto;
			right: 0;
		}

		&.v-center {
			top: 20%;
		}

		&.v-bottom {
			top: 50%;
		}

		&.anim-delay {
			animation-delay: 1.65s;
		}
	}
}
.block-bg-projects{
	background:url('../img/paralax-2.jpg') no-repeat;
	width: 100%;
	height: 100%;
	background-size: cover;
}
/*===> End parralax-section <===*/