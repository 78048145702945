/*===> Begin popup-wrap <===*/
.popup-wrap {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	width: 100%;
	height: 100%;
	padding: 15px;
	overflow-x: hidden;
	overflow-y: auto;
	background: rgba(0,0,0, .5);
	transition: all .3s;
	visibility: hidden;
	opacity: 0;

	.popup-inside {
		z-index: 10000;
		transform: scale(.5);
		margin-bottom: 0;
		margin-top: 0;
		opacity: 0;
		transition: all .3s;
	}

	&.show {
		opacity: 1;
		visibility: visible;

		.popup-inside {
			transform: scale(1);
			opacity: 1;
		}
	}
}
/*===> End popup-wrap <===*/