/*Begin fonts*/

@font-face {
  font-family: 'recording';
  src:  url('fonts/recording.eot?tc8bkk');
  src:  url('fonts/recording.eot?tc8bkk#iefix') format('embedded-opentype'),
	url('fonts/recording.ttf?tc8bkk') format('truetype'),
	url('fonts/recording.woff?tc8bkk') format('woff'),
	url('fonts/recording.svg?tc8bkk#recording') format('svg');
  font-weight: normal;
  font-style: normal;
}

.rec-icon {
	font-family: 'recording' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.icon-bag:before {
  content: "\e93b";
}
.icon-promotion-1:before {
  content: "\e900";
}
.icon-audio:before {
  content: "\e903";
}
.icon-settings:before {
  content: "\e904";
}
.icon-mic:before {
  content: "\e905";
}
.icon-promotion:before {
  content: "\e906";
}
.icon-interface:before {
  content: "\e907";
}
.icon-people:before {
  content: "\e913";
}
.icon-travel:before {
  content: "\e914";
}
.icon-quaver:before {
  content: "\e916";
}
.icon-folded-newspaper:before {
  content: "\e917";
}
.icon-picture:before {
  content: "\e919";
}
.icon-quotation:before {
  content: "\e91b";
}
.icon-telephone:before {
  content: "\e91c";
}
.icon-clock:before {
  content: "\e91d";
}
.icon-music-headphones:before {
  content: "\e91e";
}
.icon-music-cd:before {
  content: "\e91f";
}
.icon-music-cd-1:before {
  content: "\e920";
}
.icon-music-equalizer:before {
  content: "\e924";
}
.icon-mic-2:before {
  content: "\e925";
}
.icon-music-equalizer-1:before {
  content: "\e926";
}
.icon-play:before {
  content: "\e928";
}
.icon-sound:before {
  content: "\e929";
}
.icon-social:before {
  content: "\e92a";
}
.icon-note:before {
  content: "\e92b";
}
.icon-square:before {
  content: "\e92c";
}
.icon-mic-3:before {
  content: "\e92d";
}
.icon-big-speech-balloon:before {
  content: "\e92e";
}
.icon-sky:before {
  content: "\e92f";
}
.icon-placeholder-for-map:before {
  content: "\e901";
}
.icon-facebook-logo:before {
  content: "\e908";
}
.icon-twitter-logo:before {
  content: "\e909";
}
.icon-googleplus-logo:before {
  content: "\e90a";
}
.icon-linkedin-logo:before {
  content: "\e90b";
}
.icon-star:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-remove:before {
  content: "\e90f";
}
.icon-three:before {
  content: "\e910";
}
.icon-arrow-top:before {
  content: "\e921";
}
.icon-arrow-left:before {
  content: "\e937";
}
.icon-arrow-right:before {
  content: "\e936";
}
.icon-arrow-down:before {
  content: "\e911";
}
.icon-left-arrow:before {
  content: "\e912";
}
.icon-right-arrow:before {
  content: "\e915";
}
.icon-user:before {
  content: "\e91a";
}
.icon-play-circle:before {
  content: "\e927";
}
.icon-pause-circle:before {
  content: "\e902";
}
.icon-next-track:before {
  content: "\e918";
}
.icon-prev-track:before {
  content: "\e923";
}
.icon-like:before {
  content: "\e922";
}
.icon-sound-on:before {
  content: "\e930";
}
.icon-bulb:before {
  content: "\e931";
}
.icon-mail:before {
  content: "\e932";
}
.icon-facebook-logo-lined:before {
  content: "\e933";
}
.icon-twitter-logo-lined:before {
  content: "\e934";
}
.icon-instagram-logo-lined:before {
  content: "\e935";
}
.icon-download:before {
  content: "\e939";
}
.icon-calendar:before {
  content: "\e938";
}
.icon-share:before {
  content: "\e93a";
}
/*End fonts*/