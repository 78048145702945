/*===> Begin contact-list <===*/
.contact-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;

	li {
		padding-left: 0;
		width: 33.33%;
		display: flex;

		&::after {
			display: none;
		}
	}

	.contact-icon {
		margin-right: 15px;

		svg {
			path {
				fill: var(--main_color);
			}
		}
	}

	.contact-list_text {
		h5 {
			color: #fff;
		}

		a, p {
			display: block;
			margin-bottom: 0;
			color: var(--text_gray);
		}

		a {
			&:hover {
				color: var(--main_color);
			}
		}
	}

	@include breakpoint(small) {
		.contact-list_text {
			h5 {
				font-size: 16px;
			}

			a, p {
				font-size: 15px;
			}
		}
	}

	@include breakpoint(extra-small) {
		flex-direction: column;
		align-items: center;
		li {
			width: 240px;
		}
	}
}
/*===> End contact-list <===*/