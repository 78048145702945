/*===> Begin skill-list <===*/
.skill-list {
	margin-bottom: -30px;

	.custom-icon {
		margin-left: auto;
		margin-right: auto;
	}

	.icon-block {
		text-align: center;
	}
}
/*===> End skill-list <===*/