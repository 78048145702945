/*===> Begin tag-list <===*/
.tag-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: -10px;

	li {
		margin-bottom: 10px;
		padding-left: 5px;
		padding-right: 5px;

		&::after {
			display: none;
		}
	}

	a {
		padding: 5px 10px;
		border: 1px solid rgba(var(--text_gray_rgb), .5);
		border-radius: 5px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: var(--text_color);

		&:hover {
			background: var(--main_color);
			color: #fff;
		}
	}
}
/*===> End tag-list <===*/