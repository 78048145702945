/*===> Begin tile-list <===*/
.tile-list {
	.tile-list__item {
		display: flex;
		background: #fff;

		&:nth-child(odd) {
			.tile-list-content {
				margin-left: auto;
			}
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			.tile-list-content {
				margin-right: auto;
			}
		}

		&.tile-list__item--dark {
			background: var(--main_dark);

			.tile-list-text {
				h3 {
					color: #fff;
				}

				p {
					color: var(--text_gray);
				}
			}
		}

		.tile-list-text {
			width: 50%;
			padding: 75px 15px 75px 0;

			.tile-list-content {
				max-width: 580px;
				padding-left: 90px;
			}
		}

		.tile-list-img {
			width: 50%;
			background-size: cover;
			background-position: center center;
		}
	}

	@include breakpoint(small) {
		.tile-list__item {
			.tile-list-text {
				padding: 50px 15px 50px 0;

				.tile-list-content {
					padding-left: 30px;
				}
			}
		}
	}

	@include breakpoint(extra-small) {
		.tile-list__item {
			flex-direction: column;

			&:nth-child(even) {
				flex-direction: column;

				.tile-list-content {
					margin-right: 0;
					margin-left: 0;
				}
			}

			.tile-list-text {
				width: 100%;
				order: 1;
				padding: 40px 15px 40px 15px;

				.tile-list-content {
					margin-right: 0;
					margin-left: 0;
					padding-left: 0;
				}
			}

			.tile-list-img {
				order: 0;
				width: 100%;
				height: 400px;
			}
		}
	}

	@include breakpoint(mobile) {
		.tile-list__item {
			.tile-list-img {
				height: 250px;
			}
		}
	}
}
/*===> End tile-list <===*/