/*===> Begin project-slider <===*/
.project-slider {
	margin-left: -10px;
	margin-right: -10px;

	.tt-slide {
		padding-left: 10px;
		padding-right: 10px;
	}
}
/*===> End project-slider <===*/