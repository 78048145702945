/*===> Begin page-name <===*/
.page-name {
	background: var(--main_dark);
	.dark-line {
		width: 100%;
		height: 88px;
		display: none;
	}

	.page-name-content {
		position: relative;
		overflow: hidden;
		padding: 50px 0;
		background-size: cover !important;
		background-position: center center !important;

		.container {
			position: relative;
			z-index: 10;
		}

		h1 {
			text-align: center;
			color: #fff;
		}

		.page-name__bg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			min-height: 100%;
			max-width: none;
			opacity: .3;
		}
	}
}
/*===> End page-name <===*/