/*===> Begin v-separator <===*/
.v-separator {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, 50%);
		width: 4px;
		height: 63px;
		border-radius: 4px;
		background: linear-gradient(to top, var(--main_color) 0%, var(--main_color_2) 100%);
	}

	@include breakpoint(small) {
		&::after {
			height: 46px;
		}
	}

	@include breakpoint(mobile) {
		&::after {
			height: 30px;
		}
	}
	@media (max-width: 575px){
		&::after{
			display: none;
		}
	}
}
/*===> End v-separator <===*/