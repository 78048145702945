/*===> Begin clients-slider <===*/
.clients-slider {
	overflow: hidden;
	.slick-list {
		margin-left: -6px;
		margin-right: -6px;
		padding-left: 0 !important;
	}

	.clients-slider__item {
		position: relative;
		padding-left: 3px;
		padding-right: 3px;

		p {
			position: absolute;
			bottom: 10px;
			left: 15px;
			margin-bottom: 0;
			font-size: 17px;
			font-weight: 700;
			color: #fff;
		}
	}
}
/*===> End clients-slider <===*/