/*===> Begin porduct-list-top <===*/
.porduct-list-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-left: -10px;
	margin-right: -10px;

	>p {
		margin-bottom: 20px;
		padding: 0 10px;
	}

	.product-list-filter {
		min-width: 240px;
		padding: 0 10px;

		.selectWrapper {
			margin-bottom: 20px;
		}
	}

	.top-pagination {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 240px;
		padding: 0 10px;

		>p {
			margin-bottom: 20px;
		}

		.tt-pagination  {
			margin-top: 0;
			margin-bottom: 20px;

			li {
				margin-right: 10px;
			}
		}
	}

	@include breakpoint(extra-small) {
		flex-wrap: wrap;
	}
}
/*===> End porduct-list-top <===*/