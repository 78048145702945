/*===> Begin to-top <===*/
.to-top {
	position: fixed;
	bottom: 40px;
	right: 40px;
	width: 60px;
	height: 60px;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: var(--main_color);
	opacity: 0;
	visibility: hidden;
	transition: all .3s;
	cursor: pointer;

	&.show-up {
		opacity: 1;
		visibility: visible;
	}

	&:hover {
		background: var(--main_color_2);
	}

	@include breakpoint(small) {
		bottom: 60px;
		right: 20px;
		width: 40px;
		height: 40px;
	}
}
/*===> End to-top <===*/