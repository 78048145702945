/*===> Begin start video btn <===*/
.start-video-wrap {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	transform: translateX(-100%) translateY(-50%) translateZ(80px);
	transition: all .3s;

	&:hover {
		&::before,
		&::after {
			background: var(--main_color_2);
		}

		.start-video {
			background: var(--main_color_2);
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: var(--main_color);
		opacity: .5;
		animation: pulse 1.3s infinite linear;
		transform: translate(-50%, -50%);
		transition: all .3s;
	}

	&::after {
		display: none;
		animation-delay: .4s;
	}

	.start-video {
		position: relative;
		z-index: 10;
		width: 94px;
		height: 93px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: var(--main_color);
		box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.4);
		transition: all .3s;
	}

	svg {
		margin-left: 6px;
	}

	path {
		transition: all .3s;
		fill: #fff;
	}

	@include breakpoint(small) {
		.start-video {
			width: 73px;
			height: 73px;
		}
	}
}
/*===> End start video btn <===*/